.calendly-inline-widget {
    overflow: hidden !important;
    min-height: 46rem !important;
}

@media screen and (max-width: 1031px){
    .calendly-inline-widget {
        min-height: 56rem !important;
    }
}
@media screen and (max-width: 681px){
    .calendly-inline-widget {
        min-height: 50rem !important;
    }
}

@media (max-resolution: 150dpi) {
    .calendly-inline-widget {
        min-height: 52rem !important;
    }
}

@media screen and (max-width: 1048px) and (max-resolution: 150dpi) {
    .calendly-inline-widget {
        min-height: 70rem !important;
    }
}
@media screen and (max-width: 697px) and (max-resolution: 150dpi){
    .calendly-inline-widget {
        min-height: 64rem !important;
    }
}
