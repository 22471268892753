@font-face {
    font-family: "Sharp Grotesk";
    src: local("Sharp Grotesk"),
    url("./assets/fonts/SharpGrotesk/SharpGrotesk-Book20.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Sharp Grotesk";
    src: local("Sharp Grotesk"),
    url("./assets/fonts/SharpGrotesk/SharpGrotesk-BookItalic20.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Sharp Grotesk";
    src: local("Sharp Grotesk"),
    url("./assets/fonts/SharpGrotesk/SharpGrotesk-Light20.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Sharp Grotesk";
    src: local("Sharp Grotesk"),
    url("./assets/fonts/SharpGrotesk/SharpGrotesk-Medium20.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Sharp Grotesk";
    src: local("Sharp Grotesk"),
    url("./assets/fonts/SharpGrotesk/SharpGrotesk-SemiBoldItalic20.otf") format("opentype");
    font-weight: 600;
    font-style: italic;
}

@media (max-resolution: 150dpi){
    html {
        font-size: 80%;
    }
}

@media screen and (min-width: 1200px) {
    .box-righe {
        position: relative;
    }

    .box-righe:before, .box-righe:after {
        content: "";
        position: absolute;
        width: 15.25rem;
        padding: 1rem;
        height: 100%;
        top: 0;
        border-top: 1px solid #2E2E2E;
    }

    .box-righe:nth-of-type(2):before {
        border-top: none;
        border-bottom: 1px solid #2E2E2E
    }

    .box-righe:nth-of-type(2):after {
        border-top: 1px solid #2E2E2E;
        top: -1rem
    }
}

.special-card {
    font-size: max(0.6vw, 1rem);
    border: 1px solid white;
    padding-inline: 1.4vw ;
    height: 5.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.special-card:nth-of-type(2) {
    border-block: none;
}

.special-card:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -1.5rem;
    border-top:1px solid #9F84E1;
    width: 1.5rem;
}

.special-card:nth-of-type(3):before {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    border-top:1px solid #9F84E1;
    width: 1.5rem;
}

.special-card:nth-of-type(5):before {
    content: "";
    position: absolute;
    top: calc(50% + 0.5rem);
    color: #9F84E1;
    left: -1.5rem;
    height: 2rem;
    border-top:1px solid #9F84E1;
    width: 1.5rem;
}
.special-card:nth-of-type(5):after {
    content: "\25B2";
    position: absolute;
    top: 1.2rem;
    transform: rotate(90deg);
    left: -1.2rem;
    border: none;
    color:#9F84E1;
    width: 1rem;
    font-size: 1rem;
}

.special-card:nth-of-type(7):after, .special-card:nth-of-type(7):before {
    content: "\25B2";
    position: absolute;
    top: 2.2rem;
    transform: rotate(-90deg);
    border: none;
    left: calc(100% + 0.2rem);
    color:#9F84E1;
    width: 1rem;
    font-size: 1rem;
}

.special-card:nth-of-type(7):before {
    transform: rotate(90deg);
    left: -1.2rem;
}

.special-container:before, .special-container:after {
    content: "";
    position: absolute;
    height: 17.85rem;
    left: -1.5rem;
    top:2.58rem;
    width:1rem;
    border-left: 1px solid #9F84E1 ;
    border-bottom: 1px solid #9F84E1 ;
}

.special-container:after {
    height: 8.12rem;
    top:auto;
    bottom:2.05rem;
}

.vertical-line{
    position: absolute;
    height: 16.75rem;
    left:calc(100% + 0.45rem);
    top:12.83rem;
    width:1rem;
    border-right: 1px solid #9F84E1 ;
    border-bottom: 1px solid #9F84E1 ;
}

@media screen and (max-width: 900px){
    .vertical-line {
        top:12.85rem
    }
}

.special-blank {
    height: 3rem;
}
